import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { FootprintApiManager_ReportService } from './FootprintApiManager.report.index';


@Injectable({ providedIn: 'root' })
export class app_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.Invoices = this.injector.get(Invoices_ReportService);
    this.FootprintApiManager = this.injector.get(FootprintApiManager_ReportService);
  }
    public Utilities: Utilities_ReportService;
    public Invoices: Invoices_ReportService;
    public FootprintApiManager: FootprintApiManager_ReportService;

  public app: app_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

