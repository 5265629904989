import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  ViewChild
} from '@angular/core';

import { DropdownSingleComponent } from './components/dropdown-single.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { app_home_ComponentContextService } from './app.home.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-home',
  templateUrl: './app.home.component.html'
})
export class app_homeComponent implements OnInit {

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  toolbar = {
  };


  fieldsets = {
  };

  @ViewChild('dd_config') dd_config: DropdownSingleComponent;
  @ViewChild('dd_method') dd_method: DropdownSingleComponent;

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: app_ShellService,
private $datasources: app_DatasourceService,
private $flows: app_FlowService,
private $reports: app_ReportService,
private $localization: app_LocalizationService,
private $operations: app_OperationService,
private $context: app_home_ComponentContextService,
) { 

  }

  ngOnInit(): void {
    this.$init();
  
  }

  initialized = false;

  async $init() {
    this.title = 'Home';
    this.initialized = true;
  }

  close() {
    this.$finish.emit();
  }

  typeValue = null;
  typeList = [
    {
      key: 6,
      name: 'Datasource'
    },
    {
      key: 9,
      name: 'Function'
    }
  ];

  async typeDisplayWithFn(value: any): Promise<string> {
    return this.typeList.find(item => item.key === value)?.name;
  }

  async typeOptionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {
    if (this.$utils.isDefinedTrimmed(filterText)) {
      return { list: this.typeList.filter(item => item.name.startsWith(filterText)), totalCount: this.typeList.length };
    } else {
      return { list: this.typeList, totalCount: this.typeList.length };
    }
  }

  typeValueChange(value) {
    this.typeValue = value;
    this.configValueChange(null);
    this.dd_config.clearListData();
  }


  configValue = null;
  config = null;
  configList = [
    {
      key: 'app/functions/TPM_invoicing_main',
      name: 'app/functions/TPM_invoicing_main',
      referenceName: 'TPM_invoicing_main',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'instructionId',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'instructionName',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'output',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'projectIdsToInclude',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'projectIdsToExclude',
            type: 'number',
            isCollection: true
          },
          {
            required: true,
            id: 'isIRA',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'limitToFirstOfTheMonth',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'priceType',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'app/functions/TPM_invoicing_update_billing_records',
      name: 'app/functions/TPM_invoicing_update_billing_records',
      referenceName: 'TPM_invoicing_update_billing_records',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'wait',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'run',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'isIRA',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'projectIdsToInclude',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'projectIdsToExclude',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'limitToFirstOfTheMonth',
            type: 'boolean',
            isCollection: false
          },
          {
            required: false,
            id: 'priceType',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'debug',
            type: 'boolean',
            isCollection: false
          },
        ]
    },
    {
      key: 'app/functions/capture_lbma_spot_prices_flow',
      name: 'app/functions/capture_lbma_spot_prices_flow',
      referenceName: 'capture_lbma_spot_prices_flow',
      appReferenceName: 'app',
      type: 9,
    },
    {
      key: 'app/functions/capture_metal_spot_prices_flow',
      name: 'app/functions/capture_metal_spot_prices_flow',
      referenceName: 'capture_metal_spot_prices_flow',
      appReferenceName: 'app',
      type: 9,
    },
    {
      key: 'app/functions/dailyHoldingsReport',
      name: 'app/functions/dailyHoldingsReport',
      referenceName: 'dailyHoldingsReport',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'fromDate',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'toDate',
            type: 'date',
            isCollection: false
          },
          {
            required: true,
            id: 'accountNumber',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'app/functions/getActivity',
      name: 'app/functions/getActivity',
      referenceName: 'getActivity',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'startDate',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'endDate',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'ownerLookupCode',
            type: 'string',
            isCollection: false
          },
          {
            required: true,
            id: 'projectLookupCode',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'app/functions/get_customer_invoice',
      name: 'app/functions/get_customer_invoice',
      referenceName: 'get_customer_invoice',
      appReferenceName: 'app',
      type: 9,
    },
    {
      key: 'app/functions/get_holdings',
      name: 'app/functions/get_holdings',
      referenceName: 'get_holdings',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'email',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'app/functions/get_metal_spot_price',
      name: 'app/functions/get_metal_spot_price',
      referenceName: 'get_metal_spot_price',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'app/functions/get_metal_spot_price_by_date_range',
      name: 'app/functions/get_metal_spot_price_by_date_range',
      referenceName: 'get_metal_spot_price_by_date_range',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'transaction_id',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'date_from',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'date_to',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'types',
            type: 'string',
            isCollection: true
          },
        ]
    },
    {
      key: 'app/functions/getProjectCreationDate',
      name: 'app/functions/getProjectCreationDate',
      referenceName: 'get_project_creation_date',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'startDate',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'endDate',
            type: 'string',
            isCollection: false
          },
          {
            required: false,
            id: 'projectId',
            type: 'string',
            isCollection: false
          },
        ]
    },
    {
      key: 'app/functions/get_project_holdings_by_owner_id',
      name: 'app/functions/get_project_holdings_by_owner_id',
      referenceName: 'get_project_holdings_by_owner_id',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: true,
            id: 'owner_id',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'project_ids',
            type: 'number',
            isCollection: true
          },
          {
            required: false,
            id: 'page_size',
            type: 'number',
            isCollection: false
          },
          {
            required: false,
            id: 'page',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'app/functions/populate_metal_spot_price',
      name: 'app/functions/populate_metal_spot_price',
      referenceName: 'populate_metal_spot_price',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'prices',
            type: 'object',
            isCollection: true
          },
          {
            required: false,
            id: 'days',
            type: 'number',
            isCollection: false
          },
        ]
    },
    {
      key: 'app/functions/update_spot_price',
      name: 'app/functions/update_spot_price',
      referenceName: 'update_spot_price',
      appReferenceName: 'app',
      type: 9,
        inParams: [
          {
            required: false,
            id: 'prices',
            type: 'object',
            isCollection: true
          },
        ]
    },
  ];

  async configDisplayWithFn(value: any): Promise<string> {
    return this.configList.find(item => item.key === value)?.name;
  }

  async configOptionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {
    let list = this.configList.filter(item => item.type === this.typeValue);
    const totalCount = list.length;
    if (this.$utils.isDefinedTrimmed(filterText)) {
      list = list.filter(item => item.name.startsWith(filterText));
    }

    return { list, totalCount };
  }
  
  configValueChange(value) {
    this.configValue = value;
    this.config = this.configList.find(item => item.key === value);
    this.methodValueChange(null);
    this.dd_method.clearListData();
  }

  get hasMethods():boolean {
    return this.config && this.config?.methods;
  }
  
  method = null;
  methodValue = null;

  async methodDisplayWithFn(value: any): Promise<string> {
    return value;
  }

  async methodOptionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {
    let list = this.config.methods;
    const totalCount = list.length;
    if (this.$utils.isDefinedTrimmed(filterText)) {
      list = list.filter(item => item.name.startsWith(filterText));
    }

    return { list, totalCount };
  }
  
  methodValueChange(value) {
    this.methodValue = value;
    this.result = null;    
    if (this.hasMethods) {
      this.method = this.config.methods.find(item => item.key === value);
    } else {
      this.method = null;
    }
    this.onInParamChanged();
  }


  get inParams() {
    if (this.config) {
      if (this.config.methods) {
        if (this.method) {
          return this.method.inParams;
        }
      } else {
        return this.config.inParams;
      }
    }
    return null;
  }

  previewInParams = null;
  onInParamChanged() {
    this.previewInParams = {};
    if (this.inParams && this.inParams.length) {
      const inParams: { [key: string]: any } = {};
      this.inParams.forEach(ip => {
        inParams[ip.id] = this.evaluateValue(ip.value);
      });
      this.previewInParams = inParams;
    }
  }

  evaluateValue(value: string) {
    const val = this.$utils.isDefinedTrimmed(value) ? `(${value})` : value;
    const f = new Function(`{ return ${val} }`);
    return f();
  }

  get canExecute (): boolean {
    if (!this.config) {
      return false;
    }
    if (this.config.type === 6 && !this.methodValue) {
      return false;
    } 
    return true;
  }

  result: any;
  async run() {
    if (this.config.type === 6) {
      this.result = await this.$datasources[this.config.appReferenceName][this.config.referenceName][this.methodValue](this.previewInParams);
    } else if (this.config.type === 9) {
      this.result = await this.$flows[this.config.appReferenceName][this.config.referenceName](this.previewInParams);
    }
  }  
}
