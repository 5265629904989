import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { FootprintApiManager_DatasourceService } from './FootprintApiManager.datasource.index';

import { app_TPM_ds_get_billing_aggregation_strategiesService } from './app.datasource.index';
import { app_TPM_ds_get_billing_codesService } from './app.datasource.index';
import { app_TPM_ds_get_billing_contract_line_detailsService } from './app.datasource.index';
import { app_TPM_ds_get_billing_ratesService } from './app.datasource.index';
import { app_TPM_ds_get_billing_tasksService } from './app.datasource.index';
import { app_TPM_ds_get_billing_tasks_projectsService } from './app.datasource.index';
import { app_TPM_ds_get_invoice_totalsService } from './app.datasource.index';
import { app_custom_ds_get_project_creation_dateService } from './app.datasource.index';
import { app_ds_get_expired_billing_contractsService } from './app.datasource.index';
import { app_ds_get_exported_non_IRA_project_invoicesService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
    this.FootprintApiManager = this.injector.get(FootprintApiManager_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public Invoices: Invoices_DatasourceService;
    public FootprintApiManager: FootprintApiManager_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _TPM_ds_get_billing_aggregation_strategies: app_TPM_ds_get_billing_aggregation_strategiesService;
  public get TPM_ds_get_billing_aggregation_strategies(): app_TPM_ds_get_billing_aggregation_strategiesService {
    if(!this._TPM_ds_get_billing_aggregation_strategies) {
      this._TPM_ds_get_billing_aggregation_strategies = this.injector.get(app_TPM_ds_get_billing_aggregation_strategiesService);
    }
    return this._TPM_ds_get_billing_aggregation_strategies;
  }
  private _TPM_ds_get_billing_codes: app_TPM_ds_get_billing_codesService;
  public get TPM_ds_get_billing_codes(): app_TPM_ds_get_billing_codesService {
    if(!this._TPM_ds_get_billing_codes) {
      this._TPM_ds_get_billing_codes = this.injector.get(app_TPM_ds_get_billing_codesService);
    }
    return this._TPM_ds_get_billing_codes;
  }
  private _TPM_ds_get_billing_contract_line_details: app_TPM_ds_get_billing_contract_line_detailsService;
  public get TPM_ds_get_billing_contract_line_details(): app_TPM_ds_get_billing_contract_line_detailsService {
    if(!this._TPM_ds_get_billing_contract_line_details) {
      this._TPM_ds_get_billing_contract_line_details = this.injector.get(app_TPM_ds_get_billing_contract_line_detailsService);
    }
    return this._TPM_ds_get_billing_contract_line_details;
  }
  private _TPM_ds_get_billing_rates: app_TPM_ds_get_billing_ratesService;
  public get TPM_ds_get_billing_rates(): app_TPM_ds_get_billing_ratesService {
    if(!this._TPM_ds_get_billing_rates) {
      this._TPM_ds_get_billing_rates = this.injector.get(app_TPM_ds_get_billing_ratesService);
    }
    return this._TPM_ds_get_billing_rates;
  }
  private _TPM_ds_get_billing_tasks: app_TPM_ds_get_billing_tasksService;
  public get TPM_ds_get_billing_tasks(): app_TPM_ds_get_billing_tasksService {
    if(!this._TPM_ds_get_billing_tasks) {
      this._TPM_ds_get_billing_tasks = this.injector.get(app_TPM_ds_get_billing_tasksService);
    }
    return this._TPM_ds_get_billing_tasks;
  }
  private _TPM_ds_get_billing_tasks_projects: app_TPM_ds_get_billing_tasks_projectsService;
  public get TPM_ds_get_billing_tasks_projects(): app_TPM_ds_get_billing_tasks_projectsService {
    if(!this._TPM_ds_get_billing_tasks_projects) {
      this._TPM_ds_get_billing_tasks_projects = this.injector.get(app_TPM_ds_get_billing_tasks_projectsService);
    }
    return this._TPM_ds_get_billing_tasks_projects;
  }
  private _TPM_ds_get_invoice_totals: app_TPM_ds_get_invoice_totalsService;
  public get TPM_ds_get_invoice_totals(): app_TPM_ds_get_invoice_totalsService {
    if(!this._TPM_ds_get_invoice_totals) {
      this._TPM_ds_get_invoice_totals = this.injector.get(app_TPM_ds_get_invoice_totalsService);
    }
    return this._TPM_ds_get_invoice_totals;
  }
  private _custom_ds_get_project_creation_date: app_custom_ds_get_project_creation_dateService;
  public get custom_ds_get_project_creation_date(): app_custom_ds_get_project_creation_dateService {
    if(!this._custom_ds_get_project_creation_date) {
      this._custom_ds_get_project_creation_date = this.injector.get(app_custom_ds_get_project_creation_dateService);
    }
    return this._custom_ds_get_project_creation_date;
  }
  private _ds_get_expired_billing_contracts: app_ds_get_expired_billing_contractsService;
  public get ds_get_expired_billing_contracts(): app_ds_get_expired_billing_contractsService {
    if(!this._ds_get_expired_billing_contracts) {
      this._ds_get_expired_billing_contracts = this.injector.get(app_ds_get_expired_billing_contractsService);
    }
    return this._ds_get_expired_billing_contracts;
  }
  private _ds_get_exported_non_IRA_project_invoices: app_ds_get_exported_non_IRA_project_invoicesService;
  public get ds_get_exported_non_IRA_project_invoices(): app_ds_get_exported_non_IRA_project_invoicesService {
    if(!this._ds_get_exported_non_IRA_project_invoices) {
      this._ds_get_exported_non_IRA_project_invoices = this.injector.get(app_ds_get_exported_non_IRA_project_invoicesService);
    }
    return this._ds_get_exported_non_IRA_project_invoices;
  }
}

