import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_TPM_invoicing_update_billing_recordsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { wait?: boolean, run?: boolean, transaction_id?: string, isIRA?: boolean, projectIdsToInclude?: number[], projectIdsToExclude?: number[], limitToFirstOfTheMonth?: boolean, priceType?: string, debug?: boolean }): Promise<{ output?: any[], billing_tasks?: { Id?: number, Amount?: number, ApplicableRate?: number }[] }> 
  {
    let url = `${environment.backendUrl}api/app/functions/TPM_invoicing_update_billing_records`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

