import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { app_homeComponent } from './app.home.component';

import { Utilities_ShellService } from './Utilities.shell.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { FootprintApiManager_ShellService } from './FootprintApiManager.shell.service';

@Injectable({ providedIn: 'root' })
export class app_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
    public Invoices: Invoices_ShellService,
    public FootprintApiManager: FootprintApiManager_ShellService,
  ) {
    super(dialog, toastr);
  }

  public app: app_ShellService = this;

  public openhome(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Footprint Api',
        referenceName: 'home',
        component: app_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_homeComponent,
      'Footprint Api',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'home') {
      const title = 'Footprint Api';
      const component = app_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Invoices.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootprintApiManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
