
import { Injectable, Inject } from '@angular/core';

export enum ApplicationType {
  web = 'web',
  api = 'api',
  portal = 'portal',
  mobile = 'mobile',
  componentmodule = 'componentmodule'
}

export enum ComponentType {
  shell = 'shell',
  form = 'form',
  datasource = 'datasource',
  editor = 'editor',
  grid = 'grid',
  selector = 'selector',
  flow = 'flow',
  widget = 'widget',
  hub = 'hub',
  composite = 'composite',
  card = 'card',
  calendar = 'calendar',
  wizard = 'wizard',
  list = 'list',
  report = 'report',
  codeEditor = 'codeEditor',
  footprintFlow = 'footprintFlow',
  footprintDatasource = 'footprintDatasource',
  embed = 'embed',
  footprintWorkflow = 'footprintWorkflow',
  backendTest = 'backendTest',
  visualization = 'visualization',
  customType = 'customType'
}

export interface IPackageInfo {
  name: string;
  uniqueIdentifier: string;
}

export interface IApplicationInfo extends IPackageInfo {
  type: ApplicationType;
}

export interface IComponentInfo {
  name: string;
  type: ComponentType;
}

export interface IEnvironmentInfo {
  name: string;
}

export interface IEnvironmentContextService {
  env: IEnvironmentInfo;
}

@Injectable({ providedIn: 'root' })
export class EnvironmentContextService implements IEnvironmentContextService {
  public env: IEnvironmentInfo;
}

export interface IAppContextService extends IEnvironmentContextService {
  app: IApplicationInfo;
}

@Injectable({ providedIn: 'root' })
export class AppContextService implements IAppContextService {
  private readonly environmentContext: IEnvironmentContextService;
  
  constructor(@Inject(EnvironmentContextService) envContext: IEnvironmentContextService){
    this.environmentContext = envContext;
  }

  public get env(): IEnvironmentInfo {
    return this.environmentContext.env;
  }

  public get app(): IApplicationInfo {
    return {
      name: 'Footprint Api',
      type: ApplicationType.api,
      uniqueIdentifier: 'tpm-footprint-api'
    };
  }
}