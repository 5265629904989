import { Injectable } from '@angular/core';


@Injectable({providedIn: 'root'})
export class SettingsValuesService {


  private _SettingsService: any = {};
  private _isInitialized = false;

  public set SettingsService(value: any) {
    this._isInitialized = true;
    this._SettingsService = value;
  }
  public get SettingsService() {
    return this._SettingsService;
  }

  public get isInitialized() {
    return this._isInitialized;
  }

  public get Adverb_SFTP() {
    return this._SettingsService['Adverb_SFTP'];
  }
  public get FootprintApi() {
    return this._SettingsService['FootprintApi'];
  }
  public get MongoDb() {
    return this._SettingsService['MongoDb'];
  }

  public app: SettingsValuesService = this;

  public get Utilities() {
     return {
FootprintApi : this.FootprintApi,
MongoDb : this.MongoDb
    };
  }
  public get Invoices() {
     return {
FootprintApi : this.FootprintApi,
MongoDb : this.MongoDb
    };
  }
  public get FootprintApiManager() {
     return {
FootprintApi : this.FootprintApi,
FootprintApi_ReadOnly : this.FootprintApi,
MongoDb : this.MongoDb
    };
  }
  public get Addresses() {
     return {
FootprintApi : this.FootprintApi,
FootprintApi_ReadOnly : this.FootprintApi,
MongoDb : this.MongoDb
    };
  }
  public get Owners() {
     return {
FootprintApi : this.FootprintApi,
MongoDb : this.MongoDb
    };
  }
  public get Instructions() {
     return {
FootprintApi : this.FootprintApi,
MongoDb : this.MongoDb
    };
  }
  public get Notifications() {
     return {
FootprintApi : this.FootprintApi,
MongoDb : this.MongoDb
    };
  }
  public get Materials() {
     return {
FootprintApi : this.FootprintApi,
MongoDb : this.MongoDb
    };
  }
  public get Carriers() {
     return {
FootprintApi : this.FootprintApi,
MongoDb : this.MongoDb
    };
  }
  public get PurchaseOrders() {
     return {
FootprintApi : this.FootprintApi,
FootprintApi_ReadOnly : this.FootprintApi,
MongoDb : this.MongoDb
    };
  }
  public get SerialNumbers() {
     return {
FootprintApi : this.FootprintApi,
MongoDb : this.MongoDb
    };
  }
  public get Inventory() {
     return {
FootprintApi : this.FootprintApi,
MongoDb : this.MongoDb
    };
  }
  public get Attachments() {
     return {
FootprintApi : this.FootprintApi,
MongoDb : this.MongoDb
    };
  }
  public get SalesOrders() {
     return {
FootprintApi : this.FootprintApi,
MongoDb : this.MongoDb
    };
  }
}
